import { effect, inject, Injectable, Injector } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { PageMetasSelectors } from '@store/metas';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class PageMetasService {
  private store: Store<RootState> = inject(Store);
  private meta = inject(Meta);
  private titleService = inject(Title);
  private injector = inject(Injector);
  private document = inject(DOCUMENT);
  private $metaState = this.store.selectSignal(PageMetasSelectors.selectState);

  public init(): void {
    effect(
      () => {
        const { title, description, keywords, canonicalUrl } = this.$metaState();
        this.setTitle(title);
        this.setDescription(description);
        this.setKeywords(keywords);
        this.setCanonicalUrl(canonicalUrl);
      },
      { injector: this.injector }
    );
  }

  private setTitle(title: string): void {
    if (!title) {
      return;
    }

    this.titleService.setTitle(title);
  }

  private setDescription(content: string): void {
    if (!content) {
      return;
    }

    this.meta.updateTag({ name: 'description', content });
  }

  private setKeywords(content: string): void {
    if (!content) {
      return;
    }

    this.meta.updateTag({ name: 'keywords', content });
  }

  private setCanonicalUrl(url: string): void {
    this.document.querySelector('link[rel="canonical"]')?.remove();

    if (!url) {
      return;
    }

    const link = this.document.createElement('link');

    link.rel = 'canonical';
    link.href = url.toLowerCase();
    this.document.head.appendChild(link);
  }
}
