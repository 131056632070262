import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable, NgZone } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { EnvironmentService } from '@services/environment/environment.service';

import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { IntroActions } from '@store/intro';

import { defaultSoundType, IApiV4SoundConfig, Intro, TSoundConfig, TSoundType } from '@models/index';
import { Dsp } from '@models/interfaces/_dsp/dsp.manager';

import { BrowserInfoService } from '@services/browser-info/browser-info.service';

import { BUILDVERSION, IS_SERVER, WINDOW } from '@lib/tokens';
import { WEBSID_SCRIPTS } from '@lib/constants';
import { GlobalErrorHandler } from '@services/global-error-handler';

interface IIntroConstructorArgs {
  uuid: string;
  sid: TSoundConfig;
  target: string;
  isMobile: boolean;
}

type TIntroFn = (args: IIntroConstructorArgs) => void;

declare global {
  interface Window {
    DSP: Dsp.Main;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlayIntroService {
  public currentSoundType: BehaviorSubject<TSoundType> = new BehaviorSubject(defaultSoundType);

  protected intro: Intro;
  protected scriptsLoaded = 0;
  protected window = inject(WINDOW);

  constructor(
    @Inject(IS_SERVER) protected isServer: boolean,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(BUILDVERSION) private buildVersion: { timestamp: number; version: string },
    protected store: Store<RootState>,
    protected browserInfoService: BrowserInfoService,
    protected http: HttpClient,
    protected errorHandler: GlobalErrorHandler,
    protected zone: NgZone
  ) {}

  get isICrap(): boolean {
    return this.browserInfoService.isSafari ?? false;
  }

  get isMobile(): boolean {
    return this.browserInfoService.isMobile;
  }

  get DSP(): Dsp.Main {
    return Reflect.get(this.window, 'DSP');
  }

  public async init(): Promise<boolean | void> {
    if (this.isServer) {
      return;
    }

    if (typeof this.DSP !== 'undefined') {
      return Promise.resolve(true);
    }

    return await this.importDspScripts();
  }

  public async loadIntro(intro: Intro): Promise<boolean> {
    this.intro = intro;
    const isMobile = this.isMobile;

    await intro.initAudio().then((result: boolean | IApiV4SoundConfig) => {
      if (typeof result === 'boolean') {
        return;
      }

      this.store.dispatch(IntroActions.update({ payload: { id: intro.id, audioConfig: intro.audioConfig } }));
    });

    try {
      const module = await this.importIntro();
      const introFn: TIntroFn = module.default;

      // @ts-ignore
      window.DSP.intro = new introFn({
        uuid: this.intro.fragment,
        sid: this.intro.soundConfig,
        target: '#playbox',
        isMobile,
      });

      return true;
    } catch (e) {
      // this.errorHandler.handleError(e as Error);
      return Promise.reject(e);
    }
  }

  protected importIntro(): Promise<{ default: TIntroFn }> {
    const { id } = this.intro;
    const cachebust = new Date().getTime();
    const url = [EnvironmentService.apiUrl, 'intros', id, 'intro.js'].join('/');

    return import(/* @vite-ignore */ `${url}?${cachebust}`);
  }

  public runIntro(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        this.initIntro().finally();
      });
    }, 100);
  }

  public async initIntro(): Promise<void> {
    let bufferedCbmScreen: ImageData | null = null;

    if (this.intro.metas?.cbmIntroScreen) {
      const label = this.intro.shortName.slice(0, 20).toUpperCase();
      bufferedCbmScreen = await this.DSP.drawC64Screen(label, '#playbox', 3000);
    }

    if (!this.DSP.intro) {
      return;
    }

    this.DSP.intro.init(bufferedCbmScreen);
  }

  protected setSoundType(soundType: TSoundType): void {
    this.currentSoundType.next(soundType);
  }

  public async loadScripts(): Promise<boolean> {
    if (this.scriptsLoaded === 2) {
      return true;
    }

    return this.loadWebsidScript(WEBSID_SCRIPTS[0])
      .then(() => this.loadWebsidScript(WEBSID_SCRIPTS[1]))
      .catch((e: unknown) => Promise.reject(e));
  }

  protected async loadWebsidScript(src: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const script = this.document.createElement('script');

      script.type = 'text/javascript';
      script.src = `${EnvironmentService.apiUrl}/scripts/${src}`;
      script.crossOrigin = 'anonymous';

      script.onload = () => {
        this.scriptsLoaded++;
        resolve(true);
      };

      script.onerror = e => {
        reject(e);
      };

      this.document.head.appendChild(script);
    });
  }

  protected async importDspScripts(): Promise<boolean> {
    const timestamp = this.buildVersion?.timestamp || Date.now();

    return await import(
      /* @vite-ignore */ `${EnvironmentService.apiUrl}/scripts/dsp_index.js?t=${timestamp}`
    ).then(({ Dsp, DspAudioManager, DspScreenPainter }) => {
      const dsp: Dsp.Main = new Dsp();

      dsp.audioManager = new DspAudioManager() as Dsp.AudioManager;
      dsp.screenPainter = new DspScreenPainter() as Dsp.ScreenPainter;
      window.DSP = dsp;

      return true;
    });
  }
}
