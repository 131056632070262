import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export namespace RouterActions {
  export const navigate = createAction(
    '[Router] NAVIGATE',
    props<{
      url: string;
      fragment?: string;
      params?: Params;
      queryParams?: Params;
      replaceUrl?: boolean;
      state?: Record<string, unknown>;
    }>()
  );
}
