import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { MinimalRouterStateSnapshot } from '@ngrx/router-store';

import * as fromComment from './comment/comment.reducer';
import * as fromIntro from './intro/intro.reducer';
import * as fromCrackergroup from './crackergroup/crackergroup.reducer';
import * as fromJsonLd from './jsonld/jsonld.reducer';
import * as fromPageMetas from './metas/page-metas.reducer';
import * as fromNewsEntry from './news-entry/news-entry.reducer';
import * as fromScener from './scener/scener.reducer';
import * as fromUser from './user/user.reducer';

import { CrackergroupEffects } from './crackergroup/crackergroup.effects';
import { IntroEffects } from './intro/intro.effects';
import { PageMetasEffects } from './metas/page-metas.effects';
import { NewsEntryEffects } from './news-entry/news-entry.effects';
import { RouterEffects } from './router/router.effects';
import { ScenerEffects } from './scener/scener.effects';
import { UserEffects } from './user/user.effects';
import { CommentEffects } from './comment/comment.effects';
import { GlobalEffects } from './global.effects';

import { environment } from '../../environments/environment';
import { logger } from '@lib/store-logger';

export interface RootState {
  intros: fromIntro.IIntroState;
  comments: fromComment.ICommentState;
  crackergroups: fromCrackergroup.ICrackergroupState;
  jsonLd: fromJsonLd.IJsonLdState;
  newsEntries: fromNewsEntry.INewsEntryState;
  metas: fromPageMetas.IPageMetasState;
  router: fromRouter.RouterReducerState<MinimalRouterStateSnapshot>;
  scener: fromScener.IScenerState;
  user: fromUser.IApiUserState;
}

export const initialState: RootState = {
  comments: fromComment.initialState,
  crackergroups: fromCrackergroup.initialState,
  intros: fromIntro.initialState,
  jsonLd: fromJsonLd.initialState,
  metas: fromPageMetas.initialState,
  newsEntries: fromNewsEntry.initialState,
  router: { navigationId: null, state: null },
  scener: fromScener.initialState,
  user: fromUser.initialState,
};

export const reducers: ActionReducerMap<RootState> = {
  comments: fromComment.reducer,
  crackergroups: fromCrackergroup.reducer,
  intros: fromIntro.reducer,
  jsonLd: fromJsonLd.reducer,
  metas: fromPageMetas.reducer,
  newsEntries: fromNewsEntry.reducer,
  router: fromRouter.routerReducer,
  user: fromUser.reducer,
  scener: fromScener.reducer,
};

export const metaReducers = environment.key === 'PROD_LIVE' ? [] : [logger];

export const rootEffects = [
  CommentEffects,
  CrackergroupEffects,
  IntroEffects,
  NewsEntryEffects,
  PageMetasEffects,
  RouterEffects,
  ScenerEffects,
  UserEffects,
  GlobalEffects,
];
