import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { UserActions } from './user.actions';
import { IApiUser } from '@interfaces';

import { ApiService, NotificationService } from '../../services';
import { UserTrackingService } from '@services/user-tracking/user-tracking.service';
import { NULL_ACTION } from '@models/interfaces/rxjs';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private userTrackingService: UserTrackingService,
    private notificationService: NotificationService
  ) {}

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.update),
      mergeMap(payload => {
        const userId = payload?.data?.id ?? null;
        const path = userId ? `users/update/${userId}` : 'users/update';

        return this.api.patch<IApiUser>(path, { user: payload.data || {} }).pipe(
          map(response => {
            if (response.error) {
              return UserActions.updateError({ error: response.error });
            }

            return UserActions.updateSuccess({ user: response.payload });
          })
        );
      })
    )
  );

  contact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendInquiry),
      mergeMap(({ payload }) => {
        if (!payload) {
          return of(NULL_ACTION);
        }

        return this.api.post<boolean>('/contact', { contact: payload }).pipe(
          tap(() => this.notificationService.showToast({ message: 'Your message has been sent' })),
          map(() => UserActions.sendInquirySuccess()),
          catchError(error => of(UserActions.sendInquiryEError({ error })))
        );
      })
    )
  );

  setActivity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setActivity),
        tap(({ kind, extras }) => this.userTrackingService.setActivity(kind, extras))
      ),
    { dispatch: false }
  );

  createActivity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createActivity),
      mergeMap(({ stat }) =>
        this.api.post('users/stat', null, null, { 'X-DSPSTAT': stat }).pipe(
          map(({ error }) => {
            if (error) {
              return UserActions.createActivityError({ error });
            }

            return UserActions.createActivitySuccess();
          })
        )
      )
    )
  );

  updateActivity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateActivity),
      mergeMap(({ id, payload }) =>
        this.api
          .post(`users/stat/${id}`, null, null, {
            'X-DSPSTAT': window.btoa(JSON.stringify({ id, payload })),
          })
          .pipe(map(() => UserActions.updateActivitySuccess()))
      )
    )
  );

  // checkForActiveSurveys$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.checkForActiveSurvey),
  //     mergeMap(({ userId }) =>
  //       this.api.post<Partial<ISurveyParticipation>>('users/surveys', { userId }).pipe(
  //         map(({ payload }) => {
  //           let survey: Partial<ISurveyParticipation> | null = null;
  //
  //           if (payload) {
  //             try {
  //               const [clicks, votes] = payload.data.split(',').map(val => parseInt(val, 10));
  //               survey = { ...payload, clicks, votes };
  //             } catch (e) {
  //               survey = { ...payload, clicks: 0, votes: 0 };
  //             }
  //           }
  //
  //           return UserActions.checkForActiveSurveySuccess({ survey });
  //         })
  //       )
  //     )
  //   )
  // );
  //
  // updateSurveyParticipation = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.updateSurveyParticipation),
  //     mergeMap(({ participation }) =>
  //       this.api
  //         .patch<Partial<ISurveyParticipation>>(`users/surveys/${participation.id}`, { participation })
  //         .pipe(map(() => UserActions.updateSurveyParticipationSuccess({ state: participation.state })))
  //     )
  //   )
  // );
}
