import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable, of, retry } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EnvironmentService } from '@services/environment/environment.service';

export interface IApiResponse<T> {
  status?: number;
  payload: T;
  etag?: string;
  error?: HttpErrorResponse;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  private readonly apiUrl: string;
  protected readonly defaultOptions: { observe: 'response'; withCredentials: boolean } = {
    withCredentials: true,
    observe: 'response',
  };

  constructor(private http: HttpClient) {
    this.apiUrl = EnvironmentService.apiUrl;
  }

  get<T>(
    path: string,
    params?: Params,
    extraHeaders?: Record<string, string | number>
  ): Observable<IApiResponse<T>> {
    const headers = extraHeaders ? new HttpHeaders(extraHeaders) : null;
    return this.http.get<T>(`${this.apiUrl}/${path}`, { ...this.defaultOptions, params, headers }).pipe(
      map(response => ({
        status: response.status,
        error: null,
        payload: this.buildPayload<T>(response),
        etag: response.headers.get('etag') ?? null,
      })),
      catchError(this.handleError.bind(this))
    );
  }

  post<T>(
    path: string,
    payload: unknown,
    params?: Params,
    extraHeaders?: { [key: string]: string }
  ): Observable<IApiResponse<T>> {
    return this.http
      .post<T>(`${this.apiUrl}/${path}`, payload, {
        ...this.defaultOptions,
        headers: extraHeaders,
        params,
      })
      .pipe(
        retry({ count: 3, delay: 1000 }),
        map(response => ({ status: response.status, error: null, payload: this.buildPayload<T>(response) })),
        catchError(this.handleError.bind(this))
      );
  }

  patch<T>(
    path: string,
    payload: unknown,
    params?: Params,
    extraHeaders?: { [key: string]: string }
  ): Observable<IApiResponse<T>> {
    return this.http
      .patch<T>(`${this.apiUrl}/${path}`, payload, { ...this.defaultOptions, headers: extraHeaders, params })
      .pipe(
        retry({ count: 3, delay: 1000 }),
        map(response => ({ status: response.status, error: null, payload: this.buildPayload<T>(response) })),
        catchError(this.handleError.bind(this))
      );
  }

  private buildPayload<T>(response: HttpResponse<T>): T | null {
    if (!response?.body) {
      return null;
    }

    return Object.keys(response.body).length ? response.body : null;
  }

  private handleError(error: HttpErrorResponse): Observable<IApiResponse<undefined>> {
    return of({ status: error.status, payload: null, error });
  }
}
