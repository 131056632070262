import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { EMPTY, filter, Observable, of, withLatestFrom } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RootState } from '@store/index';
import { CrackergroupSelectors } from '@store/crackergroup/crackergroup.selectors';
import { IntroActions } from '@store/intro';
import { CrackergroupActions } from './crackergroup.actions';
import { PageMetasActions } from '@store/metas';

import { IApiCrackergroup, ICrackergroupSkeleton } from '@interfaces';
import { TJsonLd } from '@models/interfaces/seo';

import { ApiService } from '../../services';

@Injectable()
export class CrackergroupEffects {
  private api = inject(ApiService);
  private actions$ = inject(Actions);
  private store = inject<Store<RootState>>(Store);

  fetchSkeletons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CrackergroupActions.fetchSkeletons),
      mergeMap(() =>
        this.api.get<ICrackergroupSkeleton[]>('groups/nav').pipe(
          map(({ error, payload }) => {
            if (error) {
              return CrackergroupActions.fetchSkeletonsError({ error });
            }

            return CrackergroupActions.fetchSkeletonsSuccess({ payload });
          })
        )
      )
    )
  );

  fetch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CrackergroupActions.fetch),
      mergeMap(({ ids }) => {
        const params: Params = ids ? { ids: ids.join(',') } : null;

        return this.api.get<IApiCrackergroup[]>('groups', params).pipe(
          mergeMap(({ error, payload }) => {
            if (error) {
              return of(CrackergroupActions.fetchError({ error }));
            }

            const intros = payload.map(({ intros }) => intros).flat();

            return [
              CrackergroupActions.fetchSuccess({ payload }),
              IntroActions.fetchSuccess({ entities: intros }),
            ];
          })
        );
      })
    )
  );

  fetchAfterSetCurrent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CrackergroupActions.setCurrent),
      filter(action => !!action.id),
      withLatestFrom(this.store.select(CrackergroupSelectors.selectCurrent)),
      mergeMap(([{ id, ifNoneMatch }, group]) =>
        of(CrackergroupActions.fetchOne({ id, ifNoneMatch: ifNoneMatch ? group?.etag : null }))
      )
    )
  );

  fetchOne$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CrackergroupActions.fetchOne),
      mergeMap(action => {
        const headers = action.ifNoneMatch ? { 'If-None-Match': action.ifNoneMatch } : null;

        return this.api.get<IApiCrackergroup[]>('groups', { ids: [action.id] }, headers).pipe(
          switchMap(({ error, payload, etag }) => {
            if (error) {
              return error.status === 304
                ? of(CrackergroupActions.notModified())
                : of(CrackergroupActions.fetchError({ error }));
            }

            payload[0].etag = etag ?? null;

            return [
              CrackergroupActions.fetchSuccess({ payload }),
              IntroActions.fetchSuccess({ entities: payload[0].intros }),
              CrackergroupActions.fetchMetasForGroup({ id: action.id }),
            ];
          })
        );
      })
    )
  );

  fetchMetasForGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CrackergroupActions.fetchMetasForGroup),
      mergeMap(({ id }) => {
        if (!id) {
          return EMPTY;
        }

        return [CrackergroupActions.fetchJsonLd({ id }), PageMetasActions.fetchForGroup({ id })];
      })
    )
  );

  fetchJsonLd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CrackergroupActions.fetchJsonLd),
      mergeMap(({ id }) =>
        this.api
          .get<TJsonLd>(`metas/groups/${id}/jsonld`)
          .pipe(
            map(({ payload, error }) =>
              error
                ? CrackergroupActions.fetchError({ error })
                : CrackergroupActions.fetchJsonLdSuccess({ id, jsonLd: payload })
            )
          )
      )
    )
  );
}
