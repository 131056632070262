import { createSelector } from '@ngrx/store';
import { IApiUserComment, UserComment } from '../../models';
import { initialState, RootState } from '../index';

export namespace CommentSelectors {
  export const selectState = (state: RootState = initialState) => state.comments;

  export const selectForIntro = (introId: number = null) =>
    createSelector(selectState, state => {
      if (!introId) {
        return null;
      }

      const entities: IApiUserComment[] = Object.values(state?.entities || {});

      return entities
        .filter(comment => comment.introId === introId)
        .map(comment => new UserComment(comment))
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    });
}
