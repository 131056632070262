import { Injectable } from '@angular/core';

import { mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ScenerActions } from './scener.actions';

import { IScener } from '@models/interfaces/scener';

import { ApiService } from '../../services';

@Injectable()
export class ScenerEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService
  ) {}

  fetchByUid$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenerActions.fetchByUuid),
      mergeMap(({ uuid }) =>
        this.api.get<IScener>(`sceners/${uuid}`).pipe(
          map(({ payload, error }) => {
            if (payload) {
              return ScenerActions.fetchByUidSuccess({ entity: payload });
            }
            return ScenerActions.fetchError({ error });
          })
        )
      )
    )
  );

  fetchForLetter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenerActions.fetchForLetter),
      mergeMap(({ letter }) =>
        this.api.get<IScener[]>(`sceners/letters/${letter}`).pipe(
          map(({ payload, error }) => {
            if (payload) {
              return ScenerActions.fetchForLetterSuccess({ letter, entities: payload });
            }
            return ScenerActions.fetchError({ error });
          })
        )
      )
    )
  );
}
