import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Action, createReducer, on } from '@ngrx/store';
import { IntroActions } from './intro.actions';

import { IApiIntro, IIntroResource } from '@interfaces';

export interface IIntroState extends EntityState<IApiIntro> {
  playing: number;
  scrolltexts: { [key: string]: string[] };
  resources: { [key: string]: IIntroResource[] };
  loading: boolean;
  error: HttpErrorResponse;
  updated: number;
  hydrated: boolean;
}

const sortByPublishedAt = (a: IApiIntro, b: IApiIntro): number => {
  return a.metas?.publishedAt > b.metas?.publishedAt ? 1 : -1;
};

export const adapter: EntityAdapter<IApiIntro> = createEntityAdapter<IApiIntro>({
  sortComparer: sortByPublishedAt,
});

export const initialState: IIntroState = adapter.getInitialState({
  playing: null,
  scrolltexts: {},
  resources: {},
  loading: false,
  error: null,
  updated: null,
  hydrated: false,
});

export type State = Readonly<typeof initialState>;

const introReducer = createReducer(
  initialState,

  // REHYDRATE

  on(IntroActions.rehydrate, (state, { payload }) => ({ ...payload, hydrated: true })),

  // PLAY

  on(IntroActions.play, (state, { id }) => {
    const intro = state.entities[id];

    if (!intro) {
      return state;
    }

    return { ...state, playing: id };
  }),

  // PLAY_RANDOM

  on(IntroActions.playRandom, state => ({ ...state, error: null, loading: true })),
  on(IntroActions.playRandomError, (state, { error }) => ({ ...state, error, loading: false })),

  // STOP

  on(IntroActions.stop, state => ({ ...state, playing: null })),

  // RESET

  on(IntroActions.reset, state => adapter.removeAll({ ...state, updated: Date.now() })),

  // UPDATE

  on(IntroActions.update, (state, { payload }) =>
    adapter.updateOne({ id: payload.id, changes: payload }, { ...state, updated: Date.now() })
  ),

  // FETCH FULL // LATEST

  on(IntroActions.fetch, state => ({ ...state, loading: true, error: null })),
  on(IntroActions.fetchSuccess, IntroActions.fetchLatestSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, {
      ...state,
      loading: false,
      updated: Date.now(),
    })
  ),
  on(IntroActions.fetchError, (state, { error }) => ({ ...state, loading: false, error })),

  // FETCH SCROLLTEXTS

  on(IntroActions.fetchScrolltexts, state => ({ ...state, loading: true, error: null })),
  on(IntroActions.fetchScrolltextsSuccess, (state, { id, texts }) => {
    return {
      ...state,
      loading: false,
      scrolltexts: { ...state.scrolltexts, [id]: texts },
      updated: Date.now(),
    };
  }),
  on(IntroActions.fetchScrolltextsError, (state, { error }) => ({ ...state, loading: false, error })),

  // FETCH RESOURCES

  on(IntroActions.fetchResources, state => ({ ...state, loading: true, error: null })),
  on(IntroActions.fetchResourcesSuccess, (state, { id, payload }) => {
    return {
      ...state,
      loading: false,
      resources: { ...state.resources, [id]: payload },
      updated: Date.now(),
    };
  }),
  on(IntroActions.fetchResourcesError, (state, { error }) => ({ ...state, loading: false, error })),

  // RATE

  on(IntroActions.rateSuccess, (state, { payload }) =>
    adapter.upsertOne(payload, { ...state, loading: false, updated: Date.now() })
  ),
  on(IntroActions.rateError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return introReducer(state, action);
}
