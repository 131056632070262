export class HttpHelper {
  static reloadPage(paramKey: string = null, paramValue: string = null): void {
    const url = this.getUrl();

    if (!url || typeof window === 'undefined') {
      return;
    }

    if (paramKey && paramValue) {
      const searchParams = this.getSearchParams(url);
      searchParams.set(paramKey, paramValue);
    }

    window.location.href = url.toString();
  }

  static getSearchParams(url: URL = null): URLSearchParams {
    url ||= this.getUrl();

    return url?.searchParams || new URLSearchParams();
  }

  static searchParamExits(paramKey: string): boolean {
    return !!this.getSearchParams().get(paramKey);
  }

  static getUrl(): URL | null {
    try {
      return new URL(location.href);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }
}
