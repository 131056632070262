import { IApiNewsEntry, INewsEntry } from '@models/interfaces/news-entry';

export class NewsEntry implements INewsEntry {
  public id: number;
  public publishedAt: Date;
  public title: string;
  public message?: string;
  public description?: string;
  public intro: INewsEntry['intro'] = null;
  public archived: boolean;
  public extras?: string;
  public thumb?: string;

  constructor(args: Partial<IApiNewsEntry> = {}) {
    this.setProps(args);
  }

  get year(): number {
    return this.publishedAt.getFullYear();
  }

  get month(): number {
    return this.publishedAt.getMonth();
  }

  private setProps(args: Partial<IApiNewsEntry>): void {
    Object.entries(args).forEach(prop => {
      const key = prop[0] as keyof IApiNewsEntry;
      const value = prop[1] as IApiNewsEntry[typeof key];

      if (!value) {
        return;
      }

      if (key === 'publishedAt') {
        this.publishedAt = new Date(value as string);
        return;
      }

      Reflect.set(this, key, value);
    });
  }
}
