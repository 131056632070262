import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterActions } from './router.actions';

@Injectable()
export class RouterEffects {
  private actions$ = inject(Actions);
  private router = inject(Router);

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigate),
        tap(action => {
          const { url, fragment, queryParams, state } = action;
          this.router
            .navigate([url], {
              fragment: (fragment || state?.fragment) as string | undefined,
              queryParams,
              state,
            })
            .finally();
        })
      ),
    { dispatch: false }
  );
}
