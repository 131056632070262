export function checkDisplay(iterations = 0, started = Date.now()): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    requestAnimationFrame(() => {
      if (++iterations > 60) {
        return resolve(Date.now() - started < 900);
      }

      return checkDisplay(iterations, started);
    });
  });
}
