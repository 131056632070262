export class DspStorage {
  static _storage: Storage;

  static get storage(): Storage {
    this._storage ||= this.getStorage();
    return this._storage;
  }

  static setItem(key: string, value: string | number | object, storage: Storage = this.storage): void {
    if (typeof value !== 'string') {
      value = typeof value === 'object' ? JSON.stringify(value) : value.toString();
    }

    try {
      storage.setItem(key, value);
    } catch (e) {
      console.warn(e);
    }
  }

  static getItem(key: string, storage: Storage = this.storage): string | null {
    try {
      return storage.getItem(key);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  static hasItem(key: string, storage: Storage = this.storage): boolean {
    try {
      return !!storage.getItem(key);
    } catch {
      return false;
    }
  }

  static getStorage(): Storage {
    return this.tryLocalStorage() || this.trySessionStorage() || (new MemoryStorage() as unknown as Storage);
  }

  static tryLocalStorage(): Storage | null {
    if (typeof localStorage === 'undefined') {
      return null;
    }

    try {
      localStorage.setItem('DSP_STORAGE_TEST', 'bar');
      localStorage.removeItem('DSP_STORAGE_TEST');
      return localStorage;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  static trySessionStorage(): Storage | null {
    if (typeof sessionStorage === 'undefined') {
      return null;
    }

    try {
      sessionStorage.setItem('DSP_STORAGE_TEST', 'bar');
      sessionStorage.removeItem('DSP_STORAGE_TEST');
      return sessionStorage;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }
}

class MemoryStorage {
  private storage: { [key: string]: string } = {};

  public setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  public getItem(key: string): string | null {
    return this.storage[key];
  }

  public removeItem(key: string) {
    delete this.storage[key];
  }

  public hasItem(key: string): boolean {
    return Object.keys(this.storage).includes(key);
  }
}
