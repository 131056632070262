import { IScener, ScenerRating } from '../interfaces/scener';
import { BaseModel } from './base.class';

export class Scener extends BaseModel implements IScener {
  public uuid: string = null;
  public csdbId: number = null;
  public handle: string = null;
  public firstName: string = null;
  public lastName: string = null;
  public letters: string[] = [];
  public credits: { [key: string]: string[] } = {};
  public path: string = null;
  public sidIds: number[] = [];
  public rating: ScenerRating = {} as ScenerRating;
  public nrOfIntros = 0;

  constructor(args: Partial<IScener> = {}) {
    super();
    this.setProps(args);
  }

  get letter(): string {
    if (this.handleOrName) {
      return this.handleOrName.charAt(0);
    }

    return null;
  }

  get handleOrName(): string {
    return this.handle || this.name;
  }

  get name(): string {
    return this.hasName ? `${this.firstName} ${this.lastName}` : null;
  }

  get hasName(): boolean {
    return !!(this.firstName && this.lastName);
  }

  get csdbUrl(): string {
    return this.csdbId ? `https://csdb.dk/scener/?id=${this.csdbId}` : null;
  }

  get introIds(): number[] {
    return Object.keys(this.credits).map(Number);
  }

  public override toString(): string {
    if (this.handle) {
      return this.hasName ? `[${this.handle}] ${this.name}` : `[${this.handle}]`;
    }

    return this.name;
  }
}
