import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, signal } from '@angular/core';

import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { UserActions } from '@store/user/user.actions';

import { BrowserInfoService, IToast, NotificationService } from '../index';
import { HttpHelper } from '@lib/http-helper';
import { DspStorage } from '@lib/dsp-storage.class';
import { DspLogger } from '@lib/dsp-logger';
import { GlobalErrorHandler } from '@services/global-error-handler';

import { IS_SERVER } from '@lib/tokens';
import { I_CRAP_WARNING_MESSAGE } from '@lib/constants';

import { checkDisplay } from '@lib/high-refesh-display-checker';
import { environment } from '../../../environments/environment';
import * as BUILD_VERSION from '../../../public/version.json';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  static apiUrl = `${environment.apiHost}/${environment.apiVersion}/public`;

  static get isProduction(): boolean {
    return environment.production;
  }

  static isServer = false;

  public highRefreshDisplay = signal<boolean | null>(null);
  public visibilityChange$: Subject<boolean> = new Subject();
  private versionCheckTimeout: number;

  constructor(
    @Inject(IS_SERVER) public isServer: boolean,
    @Inject(DOCUMENT) private document: Document,
    private browserInfoService: BrowserInfoService,
    private notificationService: NotificationService,
    private store: Store<RootState>
  ) {}

  public init(): void {
    this.initVersionChecking();

    this.browserInfoService
      .init()
      .then(() => {
        this.checkIconFontLoaded();
        this.checkBrowserCompatibility();
        this.checkForFacebook();
        this.checkIsSafari();
      })
      .catch(error => {
        DspLogger.warn(error, 'EnvironmentService::init');
        GlobalErrorHandler.handleError(error);
      });

    if (!this.isServer) {
      checkDisplay().then(isHighRefreshDisplay => this.highRefreshDisplay.set(isHighRefreshDisplay));
    }
  }

  public initVersionChecking(): void {
    if (this.isServer) {
      return;
    }

    this.checkVersion().finally();
    this.bindVisibilityChangeListener();
  }

  public async checkVersion(tries = 0): Promise<void> {
    clearTimeout(this.versionCheckTimeout);
    const cachebust = Date.now();

    try {
      const response = await fetch(`${environment.host}/public/version.json?t=${cachebust}`);
      const json = await response.json();

      const { version } = BUILD_VERSION;
      const browserVersion = version;
      const serverVersion = json.version;

      if (browserVersion === serverVersion || HttpHelper.searchParamExits('reloaded')) {
        return;
      }

      HttpHelper.reloadPage('reloaded', Date.now().toString());
    } catch (e) {
      const nextTrySeconds = Math.max(tries * 2, 5);

      DspLogger.warn(
        e,
        `EnvironmentService::checkVersion. Retrying in ${nextTrySeconds} seconds (tries: ${tries})`
      );

      this.versionCheckTimeout = window.setTimeout(() => this.checkVersion(tries + 1), nextTrySeconds * 1000);
    }
  }

  private bindVisibilityChangeListener(): void {
    if (!('visibilityState' in this.document)) {
      return;
    }

    this.document.addEventListener('visibilitychange', () => {
      this.visibilityChange$.next(this.document.visibilityState === 'visible');

      if (this.document.visibilityState !== 'visible') {
        return;
      }

      this.checkVersion().finally();
    });
  }

  private checkBrowserCompatibility(): void {
    if (this.isServer || this.browserInfoService.satisfiesMinVersion) {
      return;
    }

    this.store.dispatch(
      UserActions.setActivity({ kind: `Browser warning shown (${this.browserInfoService.fullName})` })
    );

    this.notificationService.showToast({
      message: "It seems like you're using an outdated browser - newer Intros will not run on it.",
      icon: 'report_problem_outline',
      bgColor: 'var(--dsp-color-red)',
    });
  }

  private checkIsSafari(): void {
    if (
      this.isServer ||
      !this.browserInfoService.isSafari ||
      DspStorage.hasItem('SAFARI_WARNING', sessionStorage)
    ) {
      return;
    }

    const toast: IToast = {
      message: I_CRAP_WARNING_MESSAGE,
      bgColor: 'var(--dsp-color-red)',
      icon: 'info_outline',
    };

    DspStorage.setItem('SAFARI_WARNING', '1', sessionStorage);
    this.store.dispatch(UserActions.showToast({ toast }));
  }

  private checkForFacebook(): void {
    if (!this.browserInfoService.isFacebook) {
      return;
    }

    this.store.dispatch(UserActions.setActivity({ kind: 'Facebook warning shown' }));

    this.notificationService.showToast({
      message:
        "It seems like you're using Facebook's internal browser. Please use a regular browser in case you run into issues.",
      icon: 'report_problem_outline',
      bgColor: 'var(--dsp-color-red)',
    });
  }

  checkIconFontLoaded(tries = 0) {
    if (this.isServer) {
      return;
    }

    const fontLoaded = document.fonts.check('1rem Material Icons Outlined');

    if (fontLoaded || tries > 5) {
      document.body.setAttribute('data-icons-loaded', '1');
      return;
    }

    setTimeout(() => {
      this.checkIconFontLoaded(tries + 1);
    }, 500);
  }
}
