import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IBreadcrumb, IPageMetas } from '@interfaces';

export namespace PageMetasActions {
  // GET DEFAULTS

  export const getDefaults = createAction('[PageMetas] GET_DEFAULTS');
  export const getDefaultsSuccess = createAction(
    '[PageMetas] GET_DEFAULTS_SUCCESS',
    props<{ metas: IPageMetas }>()
  );
  export const getDefaultsError = createAction(
    '[PageMetas] GET_DEFAULTS_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // RESET

  export const setDefaults = createAction('[PageMetas] SET_DEFAULTS');

  // FETCH FOR GROUP

  export const fetchForGroup = createAction('[PageMetas] FETCH_FOR_GROUP', props<{ id: number }>());
  export const fetchForGroupSuccess = createAction(
    '[PageMetas] FETCH FOR GROUP SUCCESS',
    props<{ metas: IPageMetas }>()
  );
  export const fetchForGroupError = createAction(
    '[PageMetas] FETCH_FOR_GROUP_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH FOR LETTER

  export const fetchKeywordsForLetter = createAction(
    '[PageMetas] FETCH_KEYWORDS_FOR_LETTER',
    props<{ letter: string }>()
  );
  // export const fetchKeywordsForLetterSuccess = createAction('[PageMetas] FETCH_KEYWORDS_FOR_LETTER_SUCCESS');
  export const fetchKeywordsForLetterError = createAction(
    '[PageMetas] FETCH_KEYWORDS_FOR_LETTER_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH FOR SCENER

  export const fetchForScener = createAction(
    '[PageMetas] FETCH_FOR_SCENER',
    props<{ uuid?: string; letter?: string }>()
  );

  // FETCH FOR TIMELINE

  export const fetchForDecade = createAction('[PageMetas] FETCH_FOR_DECADE', props<{ decade: number }>());
  export const fetchForYear = createAction('[PageMetas] FETCH_FOR_YEAR', props<{ year: number }>());

  // SET

  export const setAll = createAction('[PageMetas] SET_ALL', props<{ metas: IPageMetas }>());
  export const setPageTitle = createAction('[PageMetas] SET_PAGE_TITLE', props<{ title: string }>());
  export const setKeywords = createAction('[PageMetas] SET_KEYWORDS', props<{ keywords: string }>());
  export const setDescription = createAction('[PageMetas] SET_DESCRIPTION', props<{ description: string }>());

  // UPDATE

  export const update = createAction('[PageMetas] UPDATE', props<{ metas: Partial<IPageMetas> }>());

  // SEND ERROR NOTIFICATION

  export const sendErrorNotification = createAction(
    '[PageMetas] SEND_ERROR_NOTIFICATION',
    props<{ userId: number; introId: number; error: string; ua: string }>()
  );

  // SET BREADCRUMBS

  export const setBreadcrumbs = createAction(
    '[PageMetas] SET_BREADCRUMBS',
    props<{ breadcrumbs: IBreadcrumb[] }>()
  );

  // THEME

  export const toggleTheme = createAction('[PageMetas] TOGGLE_THEME', props<{ dark: boolean }>());

  // ERRORS

  export const fetchError = createAction('[PageMetas] FETCH_ERROR', props<{ error: HttpErrorResponse }>());
}
