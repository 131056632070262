import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserActions } from '@store/user';

import { GlobalErrorHandler } from '@services/global-error-handler';

@Injectable({ providedIn: 'root' })
export class GlobalEffects {
  constructor(
    private errorHandler: GlobalErrorHandler,
    private actions$: Actions
  ) {}

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserActions.getCurrentError,
          UserActions.updateError,
          UserActions.createActivityError,
          UserActions.updateError
        ),
        tap(({ error }) => this.errorHandler.handleError(error))
      ),
    { dispatch: false }
  );
}
