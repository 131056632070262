import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IApiUser, IContactInquiry, IUserMetas } from '@interfaces';
import { IToast } from '@services/notification/notification.service';

export namespace UserActions {
  // get current

  // export const getCurrent = createAction('[User] GET_CURRENT', props<{ entrypoint?: string }>());
  export const getCurrentSuccess = createAction('[User] GET_CURRENT_SUCCESS', props<{ user: IApiUser }>());
  export const getCurrentError = createAction(
    '[User] GET_CURRENT_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // set current

  export const setCurrent = createAction('[User] SET_CURRENT', props<{ user: IApiUser }>());
  export const setCurrentSuccess = createAction('[User] SET_CURRENT_SUCCESS', props<{ user: IApiUser }>());

  // update

  export const update = createAction('[User] UPDATE', props<{ data: Partial<IApiUser> }>());
  export const updateMetas = createAction('[User] UPDATE_METAS', props<{ metas: Partial<IUserMetas> }>());
  export const updateSuccess = createAction('[User] UPDATE_SUCCESS', props<{ user: IApiUser }>());
  export const updateError = createAction('[User] UPDATE_ERROR', props<{ error: HttpErrorResponse }>());

  // contact

  export const showContactForm = createAction('[User] SHOW_CONTACT_FORM', props<{ subject: string }>());
  export const sendInquiry = createAction('[User] SEND_INQUIRY', props<{ payload: IContactInquiry }>());
  export const sendInquirySuccess = createAction('[User] SEND_INQUIRY_SUCCESS');
  export const sendInquiryEError = createAction(
    '[User] SEND_INQUIRY_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // tracking

  export const setActivity = createAction('[User] ADD_ACTIVITY', props<{ kind: string; extras?: string }>());
  export const createActivity = createAction('[User] CREATE_ACTIVITY', props<{ stat: string }>());
  export const createActivitySuccess = createAction('[User] CREATE_ACTIVITY_SUCCESS');
  export const createActivityError = createAction(
    '[User] CREATE_ACTIVITY_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  export const updateActivity = createAction(
    '[User] UPDATE_ACTIVITY',
    props<{ id: number; payload: Record<string, unknown> }>()
  );

  export const updateActivitySuccess = createAction('[User] UPDATE_ACTIVITY_SUCCESS');

  // notifications

  export const showToast = createAction('[User] SHOW_TOAST', props<{ toast: IToast }>());

  // check for survey

  // export const checkForActiveSurvey = createAction('[User] CHECK_FOR_ACTIVE_SURVEY', props<{ userId: number }>());
  // export const checkForActiveSurveySuccess = createAction(
  //   '[User] CHECK_FOR_ACTIVE_SURVEY_SUCCESS',
  //   props<{ survey: Partial<ISurveyParticipation> } | null>()
  // );

  // participate survey

  // export const updateSurveyParticipation = createAction(
  //   '[User] UPDATE_SURVEY_PARTICIPATION',
  //   props<{ participation: Partial<ISurveyParticipation> }>()
  // );
  // export const updateSurveyParticipationSuccess = createAction(
  //   '[User] UPDATE_SURVEY_PARTICIPATION_SUCCESS',
  //   props<{ state?: SurveyParticipationState }>()
  // );
}
