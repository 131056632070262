import { createSelector } from '@ngrx/store';
import { initialState, RootState } from '../index';
import { IApiIntro, IIntroResource, Intro, Scener } from '../../models';
import { RouterSelectors } from '@store/router';

export namespace IntroSelectors {
  export const selectState = (state: RootState = initialState) => state.intros;
  export const selectScenerState = (state: RootState = initialState) => state.scener;

  export const selectRawEntities = createSelector(selectState, state => state.entities || {});

  export const selectEntities = createSelector(selectState, state =>
    (state.ids || []).map(id => state.entities[id])
  );

  export const selectIntros = createSelector(selectEntities, entities =>
    entities.map(entity => new Intro(entity))
  );

  export const selected = createSelector(selectState, state => {
    if (!state.playing) {
      return null;
    }

    const entity: IApiIntro = state.entities[state.playing];
    return entity ? new Intro(entity) : null;
  });

  export const selectScrolltexts = createSelector(selectState, state => state.scrolltexts);

  export const selectScrolltextsForIntro = (introId: number = null) =>
    createSelector(selectScrolltexts, scrolltexts => {
      if (!introId) {
        return [];
      }

      return scrolltexts[introId] ?? [];
    });

  export const selectResources = (introId: number = null) => {
    return createSelector(selectState, state => {
      if (!introId) {
        return { credits: [], links: [] } as { credits: IIntroResource[]; links: IIntroResource[] };
      }

      return (state.resources[introId] || []).reduce(
        (p, c) => {
          const target = c.kind === 'credit' ? 'credits' : 'links';
          p[target].push(c);

          return p;
        },

        { credits: [], links: [] } as { credits: IIntroResource[]; links: IIntroResource[] }
      );
    });
  };

  export const selectCurrentScener = createSelector(
    RouterSelectors.selectParams,
    selectScenerState,
    (params, state) => {
      const uuid = params?.scenerUuid;

      if (!uuid || !state.ids?.length) {
        return null;
      }

      const entity = state.entities[uuid];
      return entity ? new Scener(entity) : null;
    }
  );

  export const selectForCurrentScener = createSelector(
    selectCurrentScener,
    selectEntities,
    (scener, entities) => {
      const introIds = scener?.introIds || [];

      return entities
        .filter(intro => introIds.includes(intro.id))
        .map(intro => new Intro(intro))
        .sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));
    }
  );

  export const forYear = (year: string | number) =>
    createSelector(selectIntros, intros => {
      year = typeof year === 'string' ? parseInt(year, 10) : year;

      return intros
        .filter(intro => intro.releasedAt?.year === year)
        .sort((a, b) => (a.releaseDate > b.releaseDate ? 1 : -1));
    });
}
