import { Action, createReducer, on } from '@ngrx/store';
import { PageMetasActions } from './page-metas.actions';

import { IBreadcrumb } from '@interfaces';
import { environment } from '../../../environments/environment';

export interface IPageMetasState {
  title: string;
  description: string;
  keywords: string;
  defaultTitle: string;
  defaultDescription: string;
  defaultKeywords: string;
  breadcrumbs: IBreadcrumb[];
  canonicalUrl: string;
  updated: number;
  hydrated: boolean;
}

export const initialState: IPageMetasState = {
  title: 'docsnyderspage.com - C64 cracker intros in your browser.',
  description:
    'C64 cracker intros recoded for the web featuring groups like Fairlight, Triad, Dominators, 711, Beastie Boys, F4CG and many many more..',
  keywords:
    'c64, commodore, 8bit, demo scene, crackergroup, cracker, crack, group, intro, crack intro, cracktro, cracker intro, scene, 2000AD, 711, A Touch of Class, ' +
    'Abyss, Acrise, Action, Action Force, Active, Actual Cracking Entertainment, Alphaflight, Arcade, Atlantis, Atrix, Avatar, ' +
    'Beastie Boys, Black Reign, Bonzai, Bros, Brutal, Byterapers, Censor Design, Chromance, Cosmos, Crazy, Crest, Crypt, Cyberpunx, ' +
    'Delta Machine, Depredators, Dominators, Doughnut Cracking Service, Druids, Dynamic Duo, Dynamix, Dytec, Eagle Soft Incorporated, ' +
    'EGA, Empire, Emulators, Epic, Equinoxe, Exact, Excalibur, Excess, Exodus, F4CG, Fairlight, Fantasy, Fantasy Cracking Service, ' +
    'FBR, Fire Eagle, Force, Frontline, Fusion, Future, Gamma Cracking Force, Genesis Project, Genetix, Hitmen, Hokuto Force, ' +
    'Hotline, Hotshot, Ikari, Intruders, Inxs, Laser, Laxity, Lazer, Legend, Level 99, Light Circle, Madsquad, Mayday, Mayhem, ' +
    'Men at work, Motiv8, The Movers, Nato, New Edition, Nirvana, North East Crackers, North East Importers, Nostalgia, Onslaught,' +
    'Orion, Papillons, Paralax, Pentacle, Plutonium Crackers, Powerrun, Pulsar, Rage for Order, Rampar, Rangers, Rebels, Red Sector, ' +
    'Remember, ROM, Ruling Company, S451, Shadows, Sharks, Shining 8, Sioux, Slaves of Keyboard, Star Alliance, Strike Force, ' +
    'Success, System of Devil, Talent, The Shaolin Monastery, Thundercats, Triad, Tristar, Underground Domain Inc, Varsity, Vision, ' +
    'Wanderer Group, Warriors of Darkness, Warriors of the Wasteland, X-Factor, X-Rated, X-Ray, Xades Society, Xenon, Xenon-NL, Yeti' +
    'Zenith, Zenobits ',
  defaultTitle: 'docsnyderspage.com - C64 cracker intros in your browser.',
  defaultDescription: '',
  defaultKeywords:
    'c64, commodore, cbm, 8bit, retro, crackergroup, cracker, crack, group, intro, crack intro, cracktro, cracker intro, scene',
  breadcrumbs: [],
  canonicalUrl: environment.host,
  updated: null,
  hydrated: false,
};

export type State = Readonly<typeof initialState>;

const metasReducer = createReducer(
  initialState,

  // getDefaults

  on(PageMetasActions.getDefaultsSuccess, (state, { metas }) => ({
    ...state,
    defaultTitle: metas.title,
    defaultDescription: metas.description,
    defaultKeywords: metas.keywords,
  })),

  // SET ALL

  on(PageMetasActions.setAll, (state, { metas }) => ({
    ...state,
    ...metas,
    updated: Date.now(),
  })),

  // UPDATE

  on(PageMetasActions.update, (state, { metas }) => ({
    ...state,
    title: metas.title || state.title,
    description: metas.description || state.description,
    keywords: metas.keywords || state.keywords,
    canonicalUrl: metas.canonicalUrl ?? state.canonicalUrl,
    updated: Date.now(),
  })),

  // RESET PAGE TITLE

  on(PageMetasActions.setDefaults, () => initialState),

  // SET PAGE TITLE

  on(PageMetasActions.setPageTitle, (state, { title }) => ({
    ...state,
    title,
    updated: Date.now(),
  })),

  // SET KEYWORDS

  on(PageMetasActions.setKeywords, (state, { keywords }) => ({
    ...state,
    keywords,
    updated: Date.now(),
  })),

  // SET DESCRIPTION

  on(PageMetasActions.setDescription, (state, { description }) => ({
    ...state,
    description,
    updated: Date.now(),
  })),

  // SET BREADCRUMBS

  on(PageMetasActions.setBreadcrumbs, (state, { breadcrumbs }) => ({
    ...state,
    breadcrumbs,
    updated: Date.now(),
  }))
);

export function reducer(state: State | undefined, action: Action): IPageMetasState {
  return metasReducer(state, action);
}
