export class DspLogger {

  static get isServer(): boolean {
    return typeof document === 'undefined';
  }

  static log(e: unknown, extras: string = ''): void {
    const message = ['[ERROR]', extras, e as Error].filter(item => !!item).join(' ');
    console.log(message);
  }

  static warn(e: unknown, extras: string = ''): void {
    const message = ['[ERROR]', extras, e as Error].filter(item => !!item).join(' ');

    if (this.isServer) {
      console.log(message);
    } else {
      console.warn(message);
    }
  }

}
