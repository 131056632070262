import { DATE_ATTR_KEYS } from '@lib/constants';

export interface IApiUserComment {
  introId: number;
  author: string;
  message: string;
  contact: string;
  createdAt?: string;
}

export interface IComment extends Omit<IApiUserComment, 'createdAt'> {
  createdAt: Date;
}

export class UserComment implements IComment {
  public introId: number = null;
  public author: string = null;
  public message: string = null;
  public contact: string = null;
  public createdAt: Date = null;

  constructor(args: Partial<IApiUserComment> = {}) {
    Object.entries(args).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(this, key)) {
        return;
      }

      if (DATE_ATTR_KEYS.includes(key) && value) {
        Reflect.set(this, key, new Date(value as string));
        return;
      }

      Reflect.set(this, key, value);
    });
  }
}
