import { inject, Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PageMetasActions } from './page-metas.actions';

import { IPageMetas } from '@interfaces';

import { ApiService, SeoService } from '../../services';

@Injectable()
export class PageMetasEffects {
  private seoService = inject(SeoService);

  // GET DEFAULTS

  getDefaults$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageMetasActions.getDefaults),
      mergeMap(() =>
        this.api.get<IPageMetas>('metas').pipe(
          map(({ error, payload }) => {
            if (payload) {
              return PageMetasActions.getDefaultsSuccess({ metas: payload });
            }
            return PageMetasActions.getDefaultsError({ error });
          })
        )
      )
    )
  );

  // FETCH FOR GROUP

  fetchForGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageMetasActions.fetchForGroup),
      mergeMap(({ id }) =>
        this.api.get<IPageMetas>(`metas/groups/${id}`).pipe(
          switchMap(({ error, payload }) => {
            if (payload) {
              return [
                PageMetasActions.fetchForGroupSuccess({ metas: payload }),
                PageMetasActions.setAll({ metas: payload }),
              ];
            }
            return of(PageMetasActions.fetchForGroupError({ error }));
          })
        )
      )
    )
  );

  // FETCH FOR LETTER

  fetchForLetter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageMetasActions.fetchKeywordsForLetter),
      mergeMap(({ letter }) => {
        const url: string = letter ? `metas/groups/letter/${letter}` : 'metas/groups/letter';
        return this.api.get<IPageMetas[]>(url).pipe(
          map(({ error, payload }) => {
            if (payload) {
              return PageMetasActions.update({ metas: { keywords: payload.join(', ') } });
            }
            return PageMetasActions.fetchKeywordsForLetterError({ error });
          })
        );
      })
    )
  );

  // FETCH FOR SCENER

  fetchForScener$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageMetasActions.fetchForScener),
      mergeMap(({ uuid, letter }) =>
        this.api.get<IPageMetas>(`metas/sceners/${uuid || letter || 'a-z'}`).pipe(
          map(({ error, payload }) => {
            if (error) {
              return PageMetasActions.fetchError({ error });
            }
            return PageMetasActions.setAll({ metas: payload });
          })
        )
      )
    )
  );

  // FETCH FOR TIMELINE

  fetchForTimeline$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageMetasActions.fetchForDecade, PageMetasActions.fetchForYear),
      mergeMap(action => {
        const path =
          action.type === PageMetasActions.fetchForDecade.type
            ? `decade/${action.decade}`
            : `year/${action.year}`;

        return this.api.get<IPageMetas>(`metas/timeline/${path}`).pipe(
          map(({ error, payload }) => {
            if (error) {
              return PageMetasActions.fetchError({ error });
            }
            return PageMetasActions.setAll({ metas: payload });
          })
        );
      })
    )
  );

  updateSeo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageMetasActions.setAll),
        tap(action => this.seoService.updateMetas(action))
      ),
    { dispatch: false }
  );

  // ERROR NOTIFICATION

  sendErrorNotification: Observable<never> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageMetasActions.sendErrorNotification),
        debounceTime(1000),
        mergeMap(() => EMPTY)
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private api: ApiService
  ) {}
}
