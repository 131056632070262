import { Action, ActionReducer } from '@ngrx/store';
import { RootState } from '@store/index';

export function logger(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return (state: RootState, action: Action): RootState => {
    const result = reducer(state, action);
    const isServer = typeof window === 'undefined';

    if (isServer) {
      return result;
    }

    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}
