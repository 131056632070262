import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IApiCrackergroup, ICrackergroupSkeleton } from '@interfaces';
import { ICrackergroupState } from '@store/crackergroup/crackergroup.reducer';
import { TJsonLd } from '@models/interfaces/seo';

export namespace CrackergroupActions {
  // REHYDRATE

  export const rehydrate = createAction(
    '[Crackergroups] REHYDRATE',
    props<{ payload: ICrackergroupState }>()
  );

  // FETCH_NAV

  export const fetchSkeletons = createAction('[Crackergroups] FETCH_SKELETONS');
  export const fetchSkeletonsSuccess = createAction(
    '[Crackergroups] FETCH_SKELETONS_SUCCESS',
    props<{ payload: ICrackergroupSkeleton[] }>()
  );
  export const fetchSkeletonsError = createAction(
    '[Crackergroups] FETCH_SKELETONS_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH

  export const fetch = createAction('[Crackergroups] FETCH', props<{ ids?: number[] }>());
  export const fetchSuccess = createAction(
    '[Crackergroups] FETCH_SUCCESS',
    props<{ payload: IApiCrackergroup[]; etag?: string }>()
  );
  export const fetchError = createAction(
    '[Crackergroups] FETCH_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  export const fetchOne = createAction(
    '[Crackergroups] FETCH_ONE',
    props<{ name?: string; id?: number; ifNoneMatch?: string }>()
  );

  export const fetchJsonLd = createAction('[Crackergroups] FETCH_JSON_LD', props<{ id: number }>());
  export const fetchJsonLdSuccess = createAction(
    '[Crackergroups] FETCH_JSON_LD_SUCCESS',
    props<{ id: number; jsonLd: TJsonLd }>()
  );

  export const notModified = createAction('[Crackergroups] NOT_MODIFIED');

  // SET_CURRENT

  export const setCurrent = createAction(
    '[Crackergroups] SET_CURRENT',
    props<{ id: number; ifNoneMatch?: boolean }>()
  );

  export const fetchMetasForGroup = createAction(
    '[Crackergroups] fetchMetasForGroup',
    props<{ id: number }>()
  );

  // RESET

  export const reset = createAction('[Crackergroups] RESET');
}
