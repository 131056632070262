import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { map, mergeMap, tap } from 'rxjs/operators';
import { CommentActions } from './comment.actions';
import { NULL_ACTION } from '@models/interfaces/rxjs';

import { IApiUserComment } from '../../models';
import { ApiService, NotificationService } from '../../services';

@Injectable()
export class CommentEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private notificationService: NotificationService
  ) {}

  // FETCH

  fetch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.fetch),
      mergeMap(({ id, introId }) => {
        const entity = introId ? 'intros' : 'groups';
        return this.api.get<IApiUserComment[]>(`comments/${entity}/${id | introId}`).pipe(
          map(({ payload, error }) => {
            if (error) {
              return CommentActions.fetchError({ error });
            }

            return CommentActions.fetchSuccess({ payload: payload || [] });
          })
        );
      })
    )
  );

  // CREATE

  create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.create),
      mergeMap(({ payload }) => {
        if (!payload) {
          return of(NULL_ACTION);
        }

        return this.api.post<IApiUserComment>('comments', { comment: payload }).pipe(
          tap(() => this.notificationService.showToast({ message: 'Thanks for creating new comment' })),
          map(({ payload, error }) =>
            payload ? CommentActions.createSuccess({ payload }) : CommentActions.createError({ error })
          )
        );
      })
    )
  );
}
