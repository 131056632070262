import { TSeoConfig } from '@models/interfaces/seo';

export const DEFAULT_SEO_CONFIG: TSeoConfig = {
  TWITTER: {
    card: 'summary_large_image',
    site: '@docsnyderspage',
    creator: 'docsnyderspage',
    title: 'docsnyderspage - c64 cracker intros in your browser',
    image: 'https://www.docsnyderspage.com/assets/images/thumbs/thumb_dark_1280_600.webp',
  },
  OG: {
    type: 'website',
    title: 'docsnyderspage - c64 cracker intros in your browser',
    url: 'https://www.docsnyderspage.com',
    image: {
      type: 'image/webp',
      secure_url: 'https://www.docsnyderspage.com/assets/images/thumbs/thumb_dark_1280_600.webp',
      width: 1280,
      height: 600,
    },
    description:
      'JS re-codes of famous C64 cracker intros from 8bit legends like Fairlight, Triad, Dominators, 711, Beastie Boys, F4CG and many many more..',
  },
};
