import { environment } from '../../../environments/environment';
import { IApiCrackergroup, ICrackergroup } from '@interfaces';
import { BaseModel } from './base.class';
import { Intro } from './intro.class';
import { TJsonLd } from '@models/interfaces/seo';

export interface ICrackergroupWebsite {
  url: string;
  label: string;
}

export class Crackergroup extends BaseModel implements ICrackergroup {
  public name: string = null;
  public path: string = null;
  public handle: string = null;
  public fqn: string = null;
  public country: string = null;
  public letter: string = null;
  public claim: string = null;
  public rating = 0;
  public websites: ICrackergroupWebsite[] = [];
  public csdbId: number = null;
  public intros: Intro[] = [];
  public introIds: number[] = [];
  public jsonLd: TJsonLd = {};

  constructor(args: Partial<IApiCrackergroup> = {}) {
    super();
    this.setProps(args);
  }

  get url(): string {
    return environment.host + this.path;
  }

  get fullName(): string {
    const nameString = this.fqn || this.name;
    return this.handle ? `${nameString} [${this.handle}]` : nameString;
  }

  get qualifiedName(): string {
    return this.fqn?.length ? this.fqn : this.name;
  }

  get fullQualifiedName(): string {
    if (this.fqn) {
      return this.handle ? `${this.fqn} [${this.handle}]` : this.fqn;
    }

    return this.handle ? `${this.name} [${this.handle}]` : this.name;
  }

  get csdbLink(): string {
    if (!this.csdbId) {
      return null;
    }

    return `https://csdb.dk/group/?id=${this.csdbId}`;
  }

  public override toString(): string {
    return this.fullName;
  }

  protected override setProps(args: Partial<IApiCrackergroup>): void {
    super.setProps(args, ['intros']);

    if (args.intros) {
      this.intros = args.intros.map(i => new Intro(i));
    }
  }
}
