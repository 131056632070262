import { DEFAULT_USER_METAS } from '@lib/constants';
import { IApiUser, IUserMetas } from '@interfaces';

export class User implements IApiUser {
  public id: number = null;
  public role: number = null;
  public cacheKey: string = null;
  public metas: IUserMetas = { ...DEFAULT_USER_METAS };

  constructor(args: Partial<IApiUser> = {}) {
    this.setProps(args || {});
  }

  get isAdmin(): boolean {
    return this.role > 0;
  }

  get darkmodeEnabled(): boolean | null {
    return this.metas?.darkmode ?? null;
  }

  get forceVoting(): boolean {
    return this.metas?.fw ?? false;
  }

  private setProps(args: Partial<IApiUser>): void {
    Object.entries(args).forEach(([key, value]) => {
      if (key === 'metas') {
        this.metas = { ...DEFAULT_USER_METAS, ...(value as IUserMetas) };
        return;
      }
      Reflect.set(this, key, value);
    });
  }
}
