import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { INewsEntryState } from '@store/news-entry/news-entry.reducer';
import { IApiNewsEntry } from '@models/interfaces/news-entry';

export namespace NewsEntryActions {
  // REHYDRATE

  export const rehydrate = createAction('[NewsEntry] REHYDRATE', props<{ state: INewsEntryState }>());

  // RESET

  export const reset = createAction('[NewsEntry] RESET');

  // FETCH YEARS

  export const fetchYears = createAction('[NewsEntry] FETCH_YEARS');
  export const fetchYearsSuccess = createAction(
    '[NewsEntry] FETCH_YEARS_SUCCESS',
    props<{ payload: number[] }>()
  );
  export const fetchYearsError = createAction(
    '[NewsEntry] FETCH_YEARS_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH FOR YEAR

  export const fetchForYear = createAction('[NewsEntry] FETCH_FOR_YEAR', props<{ year?: number }>());
  export const fetchForYearSuccess = createAction(
    '[NewsEntry] FETCH_FOR_YEAR_SUCCESS',
    props<{ payload: IApiNewsEntry[] }>()
  );
  export const fetchForYearError = createAction(
    '[NewsEntry] FETCH_FOR_YEAR_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH LATEST

  export const fetchLatest = createAction('[NewsEntry] FETCH_LATEST');
  export const fetchLatestSuccess = createAction(
    '[NewsEntry] FETCH_LATEST_SUCCESS',
    props<{ payload: IApiNewsEntry[] }>()
  );
  export const fetchLatestError = createAction(
    '[NewsEntry] FETCH_LATEST_ERROR',
    props<{ error: HttpErrorResponse }>()
  );
}
