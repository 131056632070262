import { Intro } from '@models/classes';
import { TSoundConfig, ILegacySoundConfig, IApiV4SoundConfig } from '@interfaces';
import { DEFAULT_LEGACY_SOUND_CONFIG } from '@lib/constants';
import { EnvironmentService } from '@services/environment/environment.service';

export class AudioHelper {
  private intro: Intro;

  constructor() {}

  get audioConfig(): IApiV4SoundConfig {
    return this.intro.audioConfig;
  }

  // get hasSound(): boolean {
  //   return this.hasSid || this.hasMp3;
  // }

  get hasSid(): boolean {
    return !!this.audioConfig?.sid?.uuid && !!this.audioConfig.sid?.play;
  }

  get hasMp3(): boolean {
    return !!this.audioConfig?.mp3?.uuid && !!this.audioConfig.mp3?.play;
  }

  get usesAudioManager(): boolean {
    return !!this.audioConfig?.manager;
  }

  get mp3Url(): string {
    return this.hasMp3 ? `${EnvironmentService.apiUrl}/audio/mp3/${this.audioConfig.mp3.uuid}/file` : null;
  }

  get sidUrl(): string {
    return this.hasSid ? `${EnvironmentService.apiUrl}/audio/sid/${this.audioConfig.sid.uuid}/file` : null;
  }

  get soundConfig(): TSoundConfig {
    if (this.usesAudioManager) {
      return this.audioConfig;
    }

    return this.intro.metas?.apiVersion < 4 ? this.legacySoundConfig : this.audioConfig;
  }

  get legacySoundConfig(): ILegacySoundConfig {
    let config = { ...DEFAULT_LEGACY_SOUND_CONFIG };
    const { sid, mp3 } = this.audioConfig;

    if (sid.play && sid.uuid) {
      config = {
        ...config,
        file: this.sidUrl,
        subTune: sid.subtune ?? 0,
        speed: sid.speed ?? 1.0,
        sid: this.sidUrl,
      };
    } else if (mp3.play && mp3.path) {
      config.file = 'mp3';
    }

    if (mp3.play && mp3.path) {
      config.mp3 = this.mp3Url;
    }

    return config;
  }

  public init(intro: Intro): Promise<IApiV4SoundConfig> {
    this.intro = intro;
    return this.getAudioConfig();
  }

  private async getAudioConfig(): Promise<IApiV4SoundConfig> {
    if (this.intro.audioConfig) {
      return Promise.resolve(this.intro.audioConfig);
    }

    return await this.fetchAudioConfig(this.intro.id).then(this.buildAudioConfig.bind(this));
  }

  private async fetchAudioConfig(introId: number): Promise<IApiV4SoundConfig> {
    const response = await fetch(`${EnvironmentService.apiUrl}/intros/${introId}/config/audio`);
    const json: { audioConfig: IApiV4SoundConfig } = await response.json();

    return json?.audioConfig;
  }

  private buildAudioConfig(audioConfig: IApiV4SoundConfig): IApiV4SoundConfig {
    this.intro.audioConfig = audioConfig;

    this.intro.audioConfig = {
      ...this.audioConfig,
      sid: { ...audioConfig.sid, path: this.sidUrl },
      mp3: { ...audioConfig.mp3, path: this.mp3Url },
    };

    return this.intro.audioConfig;
  }
}
