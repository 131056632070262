import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IScener } from '@models/interfaces/scener';

export namespace ScenerActions {
  export const fetchByUuid = createAction('[Scener] FETCH_BY_UID', props<{ uuid: string }>());
  export const fetchByUidSuccess = createAction('[Scener] FETCH_BY_UID_SUCCESS', props<{ entity: IScener }>());

  export const fetchForLetter = createAction('[Scener] FETCH_FOR_LETTER', props<{ letter: string }>());
  export const fetchForLetterSuccess = createAction(
    '[Scener] FETCH_FOR_LETTER_SUCCESS',
    props<{ letter: string; entities: IScener[] }>()
  );

  export const fetchError = createAction('[Scener] FETCH_ERROR', props<{ error: HttpErrorResponse }>());
}
