import { createSelector } from '@ngrx/store';
import { NewsEntry } from '../../models';
import { initialState, RootState } from '../index';
import { INewsEntryState } from './news-entry.reducer';
import { RouterSelectors } from '@store/router';

export namespace NewsEntrySelectors {
  export const selectState = (state: RootState = initialState) => state.newsEntries;

  export const selectYears = createSelector(
    selectState,
    RouterSelectors.selectParam('year'),
    (state, year) => {
      const selectedYear = year ? parseInt(year, 10) : null;

      return state.years.map(year => ({
        year,
        selected: selectedYear === year,
        filtered: selectedYear ? selectedYear !== year : false,
      }));
    }
  );

  export const selectEntries = createSelector(selectState, (state: INewsEntryState) => {
    return [...state.ids].map(id => new NewsEntry(state.entities[id]));
  });

  export const selectForYear = (year: number | string) => {
    year = typeof year === 'string' ? parseInt(year, 10) : year;
    return createSelector(selectEntries, entries => entries.filter(e => e.year === year));
  };

  export const selectLatest = createSelector(selectEntries, entries => entries.slice(0, 4));
}
