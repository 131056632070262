import { HttpErrorResponse } from '@angular/common/http';

import { Action, createReducer, on } from '@ngrx/store';
import { JsonLdActions } from './jsonld.actions';

import { TJsonLd } from '@models/interfaces/seo';

export interface IJsonLdState {
  data: { [key: string]: TJsonLd };
  error: HttpErrorResponse;
  updated: number;
}

export const initialState: IJsonLdState = {
  data: {
    site: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.docsnyderspage.com',
      logo: 'https://www.docsnyderspage.com/assets/images/logos/ds-logo-doom.png',
    },
  },
  error: null,
  updated: null,
};

export type State = Readonly<typeof initialState>;

const jsonLdReducer = createReducer(
  initialState,

  // set

  on(JsonLdActions.set, (state, { key, data }) => ({
    ...state,
    data: { ...state.data, [key]: data },
    updated: Date.now(),
  })),

  // unset

  on(JsonLdActions.unset, (state, { key }) => {
    const data = { ...state.data };
    delete data[key];

    return { ...state, data, updated: Date.now() };
  }),

  // reset

  on(JsonLdActions.reset, () => initialState)
);

export function reducer(state: State | undefined, action: Action): IJsonLdState {
  return jsonLdReducer(state, action);
}
