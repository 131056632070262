import { HttpErrorResponse } from '@angular/common/http';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CommentActions } from './comment.actions';

import { IApiUserComment } from '../../models';

export interface ICommentState extends EntityState<IApiUserComment> {
  loading: boolean;
  error: HttpErrorResponse;
  updated: number;
}

const sortByPublishedAt = (a: IApiUserComment, b: IApiUserComment): number =>
  a.createdAt > b.createdAt ? 1 : -1;

const adapter: EntityAdapter<IApiUserComment> = createEntityAdapter({ sortComparer: sortByPublishedAt });

export const initialState: ICommentState = adapter.getInitialState({
  loading: false,
  error: null,
  updated: Date.now(),
});

export type State = Readonly<typeof initialState>;

const commentReducer = createReducer(
  initialState,

  // REHYDRATE

  on(CommentActions.rehydrate, (state, { payload }) => payload),

  // FETCH

  on(CommentActions.fetch, state => ({ ...state, loading: true, error: null })),
  on(CommentActions.fetchSuccess, (state, { payload }) =>
    adapter.upsertMany(payload, { ...state, loading: false, error: null, updated: Date.now() })
  ),
  on(CommentActions.fetchError, (state, { error }) => ({ ...state, loading: false, error })),

  // CREATE

  on(CommentActions.create, state => ({ ...state, loading: true, error: null })),
  on(CommentActions.createSuccess, (state, { payload }) =>
    adapter.addOne(payload, { ...state, loading: false, updated: Date.now() })
  ),
  on(CommentActions.createError, (state, { error }) => ({ ...state, loading: false, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return commentReducer(state, action);
}
