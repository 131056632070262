import { createSelector } from '@ngrx/store';
import { initialState, RootState } from '../index';
import { User } from '@models/classes';

export namespace UserSelectors {
  export const selectUserState = (state: RootState = initialState) => state.user;

  export const selectUser = createSelector(selectUserState, state => {
    return state.entity ? new User(state.entity) : null;
  });
}
