import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

import { IFrontendError } from '@models/interfaces/frontend-error';

import { EnvironmentService } from '@services/environment/environment.service';
import { UserService } from '@services/user/user.service';
import { DspStorage } from '@lib/dsp-storage.class';

import { LOCALSTORAGE_ID_KEY } from '@lib/constants';
import { IS_SERVER, REQUEST } from '@lib/tokens';
import { HttpHelper } from '@lib/http-helper';

import { createErrorHandler, SentryErrorHandler } from '@sentry/angular';

import { environment } from '../../environments/environment';

type TErrorType = HttpErrorResponse | Error;

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler extends ErrorHandler {
  static _instance: GlobalErrorHandler;
  static get instance(): GlobalErrorHandler {
    this._instance ||= new GlobalErrorHandler();
    return this._instance;
  }

  private isServer = inject(IS_SERVER, { optional: true });
  private request = inject(REQUEST, { optional: true });
  private location = inject(Location, { optional: true });
  // @ts-ignore
  private submitTimeout: unknown;
  private readonly sentryErrorHandler: SentryErrorHandler;

  constructor() {
    super();

    if (this.isServer || environment.key !== 'PROD_LIVE') {
      return;
    }

    this.sentryErrorHandler = createErrorHandler({ showDialog: false });
  }

  static handleError(error: TErrorType, message: string = null): void {
    this.instance.handleError(error, message);
  }

  public override handleError(error: TErrorType = null, message: string = ''): void {
    if (!error) {
      return;
    }

    if (this.sentryErrorHandler) {
      this.sentryErrorHandler.handleError(error);
    }

    this.log(error);

    if ((error?.message?.toLowerCase() || '').includes('loading chunk')) {
      if (HttpHelper.searchParamExits('reloaded')) {
        return;
      }

      HttpHelper.reloadPage('reloaded', Date.now().toString());
      return;
    }

    this.tryToStopRunningIntro();

    if (error instanceof HttpErrorResponse && error.status === 204) {
      return;
    }

    const frontendError = this.buildFrontendError(error, message);
    this.submitError(frontendError);
  }

  private buildFrontendError(error: TErrorType, message: string = ''): IFrontendError {
    return {
      userId: this.getUserId(),
      introId: this.getIntroId(),
      ua: this.getUa(),
      path: this.getPath(),
      message,
      stack: this.buildStack(error),
    };
  }

  private submitError(error: IFrontendError = null): void {
    if (!error) {
      return;
    }

    this.log(error);

    this.throttled(() =>
      fetch(`${EnvironmentService.apiUrl}/internal/error-reporting`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ trace: error }),
      }).catch(console.log)
    );
  }

  private getUserId(): number | null {
    return this.safeRun(
      () => {
        if (DspStorage.hasItem(LOCALSTORAGE_ID_KEY)) {
          return parseInt(DspStorage.getItem(LOCALSTORAGE_ID_KEY), 10);
        }
        return UserService.$userId();
      },
      () => UserService.$userId()
    );
  }

  private getIntroId(): number | null {
    return this.safeRun<() => number | null>(
      () => Reflect.get<object, string>(window, 'DSP')?.intro?.id ?? null
    );
  }

  private getUa(): string | null {
    return this.safeRun((): string | null => {
      if (typeof navigator !== 'undefined') {
        return navigator.userAgent;
      }

      if (this.request) {
        return this.request.headers?.['User-Agent'] as string;
      }

      return null;
    });
  }

  private getPath(): string | null {
    return this.safeRun((): string => {
      if (typeof location !== 'undefined') {
        return location.href;
      }

      if (this.request) {
        return this.request.url;
      }

      if (this.location) {
        return this.location.path();
      }

      return null;
    });
  }

  private buildStack(error: TErrorType = null): string {
    if (!error) {
      return '';
    }

    const stack: Record<string, string | number> = {};

    ['message', 'stack', 'url', 'headers', 'status', 'statusText'].forEach(prop => {
      this.safeRun(() => {
        const property = Reflect.get(error, prop);

        if (property) {
          stack[prop] = typeof property === 'string' ? property : JSON.stringify(property);
        }
      });
    });

    stack.envKey = environment.key;

    this.safeRun(() => {
      stack.originalError = JSON.stringify(error);
    });

    return JSON.stringify(stack);
  }

  private tryToStopRunningIntro(): void {
    if (this.isServer || typeof window === 'undefined') {
      return;
    }

    this.safeRun(() => {
      const dsp = Reflect.get(window, 'DSP') || {};
      const intro = Reflect.get(dsp, 'intro');

      if (typeof intro?.stop !== 'undefined') {
        intro.stop();
      }
    });
  }

  private throttled(fn: () => unknown) {
    if (typeof setTimeout !== 'undefined') {
      //@ts-ignore
      clearTimeout(this.submitTimeout);
      this.submitTimeout = setTimeout(() => this.safeRun(fn), 1000);
      return;
    }

    this.safeRun(fn);
  }

  private safeRun<T extends () => unknown>(fn: T, fallbackFn?: T): ReturnType<T> | null {
    try {
      return fn() as ReturnType<T>;
    } catch (e) {
      if (fallbackFn) {
        try {
          return fallbackFn() as ReturnType<T>;
        } catch (e) {
          return this.log(e);
        }
      }

      return this.log(e);
    }
  }

  private log(e: unknown = null): null {
    if (!e) {
      return null;
    }

    if (!EnvironmentService.isProduction || this.isServer) {
      console.log(e);
    }

    return null;
  }
}
