import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Action, createReducer, on } from '@ngrx/store';

import { IApiCrackergroup, ICrackergroupSkeleton } from '@interfaces';
import { CrackergroupActions } from './crackergroup.actions';

export interface ICrackergroupState extends EntityState<IApiCrackergroup> {
  skeletons: ICrackergroupSkeleton[];
  current: number;
  loading: boolean;
  error: HttpErrorResponse;
  updated: number;
  hydrated: boolean;
}

export const sortByName = (a: IApiCrackergroup, b: IApiCrackergroup): number => a.name.localeCompare(b.name);

export const adapter: EntityAdapter<IApiCrackergroup> = createEntityAdapter<IApiCrackergroup>({
  sortComparer: sortByName,
});

export const initialState: ICrackergroupState = adapter.getInitialState({
  skeletons: [],
  current: null,
  loading: false,
  error: null,
  updated: null,
  hydrated: false,
});

export type State = Readonly<typeof initialState>;

const crackergroupsReducer = createReducer(
  initialState,

  // REHYDRATE

  on(CrackergroupActions.rehydrate, (state, { payload }) => ({ ...payload, hydrated: true })),

  // FETCH SKELETONS

  on(CrackergroupActions.fetchSkeletons, state => ({ ...state, loading: true, error: null })),
  on(CrackergroupActions.fetchSkeletonsSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    skeletons: (payload || []).map(group => ({
      ...group,
      path: `/groups/${group.letter}/${group.pathname}`,
    })),
  })),
  on(CrackergroupActions.fetchSkeletonsError, (state, { error }) => ({ ...state, loading: false, error })),

  // FETCH

  on(CrackergroupActions.fetch, state => ({ ...state, loading: true, error: null })),
  on(CrackergroupActions.fetchSuccess, (state, { payload }) =>
    adapter.upsertMany(payload, {
      ...state,
      loading: false,
      updated: Date.now(),
    })
  ),
  on(CrackergroupActions.fetchError, (state, { error }) => ({ ...state, loading: false, error })),
  on(CrackergroupActions.notModified, state => ({ ...state, loading: false })),

  on(CrackergroupActions.fetchJsonLdSuccess, (state, { id, jsonLd }) => {
    const now = Date.now();
    const cacheKey = `crackergroup_${id}_${now}`;
    return adapter.updateOne({ id, changes: { jsonLd, cacheKey } }, { ...state, updated: now });
  }),

  // SET CURRENT

  on(CrackergroupActions.setCurrent, (state, { id }) => ({ ...state, current: id })),

  // RESET

  on(CrackergroupActions.reset, state => ({ ...state, loading: false, current: null, updated: Date.now() }))
);

export function reducer(state: State | undefined, action: Action) {
  return crackergroupsReducer(state, action);
}
