import { HttpErrorResponse } from '@angular/common/http';

import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';

import { IApiUser } from '@interfaces';

export interface IApiUserState {
  entity: IApiUser;
  error: HttpErrorResponse;
  // survey: Partial<ISurveyParticipation> | null;
  loading: boolean;
  updatedAt: number;
}

export const initialState: IApiUserState = {
  entity: null,
  // survey: null,
  loading: false,
  error: null,
  updatedAt: null,
};

export type State = Readonly<typeof initialState>;

const userReducer = createReducer(
  initialState,

  // getCurrent

  // on(UserActions.getCurrent, state => ({ ...state, loading: true })),
  on(UserActions.getCurrentSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    entity: user,
    updatedAt: Date.now(),
  })),
  on(UserActions.getCurrentError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // setCurrent

  on(UserActions.setCurrent, state => ({ ...state, loading: true })),
  on(UserActions.setCurrentSuccess, (state, { user }) => ({
    ...state,
    loading: true,
    entity: user,
    updatedAt: Date.now(),
  })),

  // update

  on(UserActions.update, state => ({ ...state, loading: true })),
  on(UserActions.updateSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    entity: user,
    updatedAt: Date.now(),
  })),
  on(UserActions.updateMetas, (state, { metas }) => {
    const entity = structuredClone(state.entity);

    if (entity && metas) {
      entity.metas = { ...entity.metas, ...metas };
    }

    return {
      ...state,
      loading: false,
      entity,
      updatedAt: Date.now(),
    };
  }),
  on(UserActions.updateError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))

  // surveys

  // on(UserActions.checkForActiveSurveySuccess, (state, { survey }) => ({
  //   ...state,
  //   survey,
  // })),
  //
  // on(UserActions.updateSurveyParticipationSuccess, (userState, { state }) => {
  //   if (['finished', 'rejected', 'aborted'].includes(state)) {
  //     return {
  //       ...userState,
  //       survey: null,
  //     };
  //   } else {
  //     return userState;
  //   }
  // })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
