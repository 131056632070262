import { inject, Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';

import { debounceTime } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { CommentActions } from '@store/comment';
import { IntroActions } from '@store/intro';
import { CrackergroupActions } from '@store/crackergroup';
import { PageMetasActions } from '@store/metas';
import { NewsEntryActions } from '@store/news-entry';

import { IS_SERVER } from '@lib/tokens';
import { TTransferStateKey } from '@interfaces';
import { ICommentState } from '@store/comment/comment.reducer';
import { ICrackergroupState } from '@store/crackergroup/crackergroup.reducer';
import { IIntroState } from '@store/intro/intro.reducer';
import { IPageMetasState } from '@store/metas/page-metas.reducer';
import { INewsEntryState } from '@store/news-entry/news-entry.reducer';

@Injectable({ providedIn: 'root' })
export class TransferStateManager {
  private isServer = inject(IS_SERVER);
  private transferState = inject(TransferState);
  private store = inject<Store<RootState>>(Store);

  public init(): void {
    if (this.isServer) {
      this.initServer();
      return;
    }

    this.initBrowser();
  }

  public hasCollection(key: TTransferStateKey): boolean {
    return this.transferState.hasKey(this.makeKey(key));
  }

  public getCollection(key: TTransferStateKey): unknown {
    return this.transferState.get(this.makeKey(key), null);
  }

  public setCollection(key: TTransferStateKey, data: unknown): void {
    this.transferState.set(this.makeKey(key), data);
  }

  public removeCollection(key: TTransferStateKey): void {
    this.transferState.remove<string>(this.makeKey(key));
  }

  private makeKey<T>(key: TTransferStateKey): StateKey<T> {
    return makeStateKey<T>(key);
  }

  private initServer(): void {
    this.store.pipe(debounceTime(100)).subscribe((state: RootState) => {
      if (state.metas) {
        this.setCollection('METAS', state.metas);
      }

      if (state.crackergroups?.skeletons?.length) {
        this.setCollection('GROUPS', state.crackergroups);
      }

      if (state.intros?.ids?.length) {
        this.setCollection('INTROS', state.intros);
      }

      if (state.comments) {
        this.setCollection('COMMENTS', state.comments);
      }

      if (state.newsEntries?.ids?.length) {
        this.setCollection('NEWS', state.newsEntries);
      }
    });
  }

  private initBrowser(): void {
    if (this.hasCollection('COMMENTS')) {
      this.store.dispatch(
        CommentActions.rehydrate({ payload: this.getCollection('COMMENTS') as ICommentState })
      );
    }

    if (this.hasCollection('GROUPS')) {
      this.store.dispatch(
        CrackergroupActions.rehydrate({ payload: this.getCollection('GROUPS') as ICrackergroupState })
      );
    }

    if (this.hasCollection('INTROS')) {
      this.store.dispatch(IntroActions.rehydrate({ payload: this.getCollection('INTROS') as IIntroState }));
    }

    if (this.hasCollection('METAS')) {
      this.store.dispatch(PageMetasActions.setAll({ metas: this.getCollection('METAS') as IPageMetasState }));
    }

    if (this.hasCollection('NEWS')) {
      this.store.dispatch(
        NewsEntryActions.rehydrate({ state: this.getCollection('NEWS') as INewsEntryState })
      );
    }
  }
}
