import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NewsEntryActions } from './news-entry.actions';

import { ApiService } from '../../services';
import { IApiNewsEntry } from '@models/interfaces/news-entry';

@Injectable()
export class NewsEntryEffects {
  private actions$ = inject(Actions);
  private api = inject(ApiService);

  fetchYears$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsEntryActions.fetchYears),
      mergeMap(() =>
        this.api.get<number[]>('news/years').pipe(
          map(({ payload, error }) => {
            if (payload) {
              return NewsEntryActions.fetchYearsSuccess({ payload });
            }

            return NewsEntryActions.fetchYearsError({ error });
          })
        )
      )
    )
  );

  fetchForYear$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsEntryActions.fetchForYear),
      mergeMap(({ year }) => {
        const url: string = year ? `news/year/${year}` : 'news';

        return this.api.get<IApiNewsEntry[]>(url).pipe(
          map(({ payload, error }) => {
            if (payload) {
              return NewsEntryActions.fetchForYearSuccess({ payload });
            }
            return NewsEntryActions.fetchForYearError({ error });
          })
        );
      })
    )
  );

  fetchLatest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsEntryActions.fetchLatest),
      mergeMap(() =>
        this.api.get<IApiNewsEntry[]>('news/latest').pipe(
          map(({ payload, error }) => {
            if (payload) {
              return NewsEntryActions.fetchLatestSuccess({ payload });
            }
            return NewsEntryActions.fetchLatestError({ error });
          })
        )
      )
    )
  );
}
