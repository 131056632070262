import * as fromRouter from '@ngrx/router-store';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../index';
import { MinimalRouterStateSnapshot } from '@ngrx/router-store';

export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState<MinimalRouterStateSnapshot>>('router');
const selectors = fromRouter.getRouterSelectors(selectRouter);

export namespace RouterSelectors {
  export const selectState = (state: RootState) => state.router.state.root;

  export const selectUrl = selectors.selectUrl;
  // export const selectQueryParams = selectors.selectQueryParams;
  export const selectParams = selectors.selectRouteParams;
  export const selectParam = (key: string) => createSelector(selectParams, params => params[key] ?? null);
  // export const selectFragment = createSelector(selectState, state => state.fragment);
  // export const selectGroupPath = createSelector(selectParams, params => params.groupName ?? null);
}
